<template>
  <div
    id="inputSelector"
    class="pl-4 align-content-center align-items-start"
    :class="{ mobile: mobileMode === true }"
  >
    <div class="row">
      <transition name="slide-up" appear :duration="3000">
        <div
          class="col-lg-12 col-md-12 col-sm-12 card-parent"
          v-show="inputPanelButtons['Controls'].active === true"
        >
          <div
            id="inputSelectorBody"
            class="card-body frosted-glass styled-scrollbar"
            :class="{ mobile: mobileMode === true }"
          >
            <div class="row d-flex justify-content-around align-content-center">
              <div class="col-8 col-lg-8 col-sm-8 m-0 p-1 text-left">
                <div class="standard-text title p-2">Inputs</div>
                <div class="description-text pl-2">
                  model: <span class="">{{modelID}}</span>
                </div>
              </div>
              <div class="col-2 align-self-center d-flex justify-content-end">
                <div
                  class="exit-button"
                  @click="inputPanelButtons['Controls'].active = false"
                  title="close panel"
                >
                  <exitButton />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-10 col-lg-10 col-sm-10 m-0 p-1 mx-auto">
                <div
                  class="slider-parent"
                  v-for="input in inputObject"
                  :key="input.label + '-key'"
                >
                  <div class="standard-text label">{{ input.label }}</div>
                  <!-- THIS ONE DOES NOT SELECT A MODEL FOR TESTING -->
                  <!-- <vue-slider v-model="input.value" :data="input.data" :ref="input.label" v-bind="sliderOptions" :marks="markSort(input.data)">
                </vue-slider> -->
                  <!-- <vue-slider
                    :title="'adjust ' + input.label"
                    v-model="input.value"
                    :data="input.data"
                    :ref="input.ref"
                    @drag-end="setInputValues"
                    @error="sliderError"
                    :stop-propagation="true"
                    v-bind="sliderOptions"
                    :marks="input.marks"
                    :clickable="false"
                    :absorb="true"
                    :lazy="true"
                  >
                  </vue-slider> -->
                </div>
              </div>
            </div>
            <hr />
            <div class="row d-flex justify-content-around align-content-center">
              <div class="col-8 col-lg-8 col-sm-8 m-0 p-1">
                <div class="standard-text title pr-2 pt-2 pb-2 text-left">
                  Metrics
                </div>
              </div>
              <div class="col-2 align-self-center d-flex justify-content-end">
                <!-- <b-button
                  class="help-icon"
                  :class="{ active: metricTooltip === true }"
                  v-model="metricTooltip"
                  @click="metricTooltip = !metricTooltip"
                  title="legend tooltip"
                  >i</b-button
                > -->
                <div
                  class="legend-tooltip d-flex p-2"
                  v-if="metricTooltip === true"
                >
                  <div class="metric-tooltip-container label p-2 below">
                    Below Avg
                  </div>
                  <div class="metric-tooltip-container label p-2 above">
                    Above Avg
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-10 col-lg-10 col-sm-10 m-0 p-1 mx-auto">
                <div class="row" v-for="metric in metrics" :key="metric.label">
                  <div class="col-12">
                    <div
                      class="switch-parent row d-flex justify-content-between align-items-center mt-2"
                    >
                      <!-- <switch-button
                        v-model="metric.toggle"
                        :dataName="metric.dataName"
                        class="standard-text label"
                        @toggle="selectMetric(metric)"
                        :title="'adjust ' + metric.label"
                        >{{ metric.label }}
                        <span class="d-block label"
                          >{{ metric.prettyValue }} {{ metric.units }}</span
                        >
                        <div
                          class="sublabel grey"
                          v-if="metric.analysis_mesh === false"
                        >
                          no analysis mesh available
                        </div>
                      </switch-button> -->
                      <!-- <div class="star-parent d-flex align-items-start">
                        <star
                          v-for="(star, index) in metric.starRatingArray"
                          :key="index"
                          :typeOfStar="star"
                          :aboveBenchmark="metric.benchmark"
                          :width="20"
                          :height="20"
                          :starColor="null"
                        />
                      </div> -->
                    </div>
                    <transition name="metric-slide-up">
                      <div
                        class="row metric-detail"
                        v-if="metric.toggle === true"
                      >
                        <div class="col-12 col-lg-12 col-sm-12 m-0">
                          <div
                            class="row m-2 metric-gradient-parent"
                            v-if="metric.analysis_mesh === true"
                          >
                            <div
                              class="zero-gradient zero-color col-1"
                              v-if="metric.zeroScale === true"
                            ></div>
                            <div
                              :class="{
                                'col-10 col-md-10 col-sm-10':
                                  metric.zeroScale === true,
                                'col-12 col-md-12 col-sm-12 no-zero-grad':
                                  metric.zeroScale === false,
                              }"
                              class="metric-gradient"
                              ref="metric-gradient"
                              :style="{
                                background:
                                  'linear-gradient(to right,' +
                                  metric.cramp.toString() +
                                  ')',
                              }"
                            ></div>
                          </div>
                          <div
                            class="row m-2"
                            v-if="metric.analysis_mesh === true"
                          >
                            <div
                              class="zero-gradient zero-text col-1 standard-text units-text mr-1"
                              v-if="metric.zeroScale === true"
                            >
                              none
                            </div>
                            <div
                              :class="{
                                'col-10 col-md-10 col-sm-10':
                                  metric.zeroScale === true,
                                'col-12 col-md-12 col-sm-12':
                                  metric.zeroScale === false,
                              }"
                              class="justify-content-between d-flex"
                            >
                              <div
                                class="d-flex standard-text units-text"
                                v-for="(tick, i) in metric.ticks"
                                :key="i"
                              >
                                {{ tick }}
                              </div>
                            </div>
                          </div>
                          <!-- <div class="row m-2 mt-3" v-if="metric.toggle === true">
                        <div class="col-12 col-md-12 col-sm-12 d-flex align-items-center">
                          <div class="no-data-legend"></div><div class="units-text ml-2">less than or equal to 0.</div>
                        </div>
                      </div> -->
                          <div class="row m-2 mt-3 text-left">
                            <div class="col-12 col-md-12 col-sm-12">
                              <div class="description-text">
                                {{ metric.description }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
// vue slider import
//import VueSlider from 'vue-slider-component'
// slider template styling
//import 'vue-slider-component/theme/default.css'
// switch button template
// import switchButton from '@/components/explore/switchButton.vue'
// // star template
// import star from '@/components/Utility/star.vue'
// import store
import { mapGetters } from 'vuex'
// exit button import
import exitButton from '@/assets/icons/Close.svg'

// import helper functions
import { filterModelData } from '@/assets/js/helper.js'

export default {
  name: 'inputSelector',
  props: {
  },
  data () {
    return {
      /**
       * @type object
       * slider options
       */
      sliderOptions: {
        dotSize: 14,
        // "marks": true,
        lazy: true,
        tooltip: 'none',
        railStyle: {
          'background-color': 'rgba(253, 203, 204, 0.41)'
        },
        processStyle: {
          'background-color': '#ff4c5f'
        }
      },
      metricTooltip: false
    }
  },
  components: {
    // VueSlider,
    // switchButton,
    // star,
    exitButton
  },
  beforeUnmount () {
    this.unsubscribeFromStore()
  },
  async mounted () {
    this.subscribeToStore()

    /**
     * checks if routed to model page from filter page
     * and has a new model to show
     */
    // if (this.$route.params['filterUpdate'] === true) {
    //   this.awaitSliderRender(this.$route.params['ID'])
    // }
  },
  computed: {
    // modelData and settings around reactive elements bound to this instance
    // linked to changes in the store
    ...mapGetters({
      modelData: 'getModelData',
      settings: 'getSettingsData',
      selectedModel: 'getSelectedModel',
      inputHeaders: 'getInputHeaders',
      metricHeaders: 'getMetricHeaders',
      searchIterationID: 'getSearchIterationID',
      metricSelected: 'getMetricSelected',
      modelDataFlag: 'getModelDataFlag',
      metrics: 'getMetricObject',
      inputObject: 'getInputObject',
      mobileMode: 'getMobileMode',
      modelDataObject: 'getModelDataObject',
      inputPanelButtons: 'getExplorePanel',
      modelID: 'getModelID'
    })
  },
  methods: {
    /**
     * @type await a-sync method
     * waits till the next render cycle to update the input panel
     */
    // awaitSliderRender (ID) {
    //   const selectedModelObject = this.getSelectedModelObject(ID.toString())

    //   this.$store.commit('setModelDataObject', selectedModelObject)

    //   this.updateInputPanel(selectedModelObject, false)
    // },
    /**
     * @param {string} ID model ID
     * @return {object} selectedModel filtered by ID
     */
    // getSelectedModelObject (ID) {
    //   const selectedModel = {}

    //   this.modelData.forEach((d) => {
    //     if (d['iteration'] === ID) {
    //       Object.assign(selectedModel, d)
    //     }
    //   })

    //   return selectedModel
    // },
    /**
     * @param {object} selectedModelObject currently selected model object from modeldata filtered by ID
     * @param {boolean} checkMetrics whether to check if metric should be applied
     * @return no return
     * @type void
     * @note updates the input slider panel and metric readout
     */
    // updateInputPanel (selectedModelObject, checkMetrics) {
    //   this.inputHeaders.forEach((sliderRef) => {
    //     const refKey = sliderRef.split('in_')[1]
    //     this.$refs[refKey][0].setValue(selectedModelObject[sliderRef])
    //   })

    //   this.$store.commit('setModelID', selectedModelObject['iteration'])

    //   /**
    //    * @type dispatch
    //    * submit model to store to call API
    //    */
    //   this.$store.dispatch('readModelByID', {
    //     ID: selectedModelObject['iteration'] + '_option',
    //     type: 'model'
    //   })
    //   // once model has been selected update star rating
    //   this.updateStarRatings(selectedModelObject, this.metricHeaders)
    //   // check to see if metric was loaded
    //   if (checkMetrics) this.checkMetricStatus()
    // },
    /**
     * @param {string} metric selected metric
     */
    // selectMetric (metric) {
    //   for (var key in this.metrics) {
    //     if (this.metrics[key]['dataName'] !== metric['dataName']) {
    //       if (this.metrics[key].toggle === true) {
    //         this.metrics[key].toggle = false

    //         if (this.metricSelected['analysis_mesh'] === true) {
    //           this.$emit('callRemoveModel', this.metricSelected['metric'])
    //         }
    //       }
    //       this.metrics[key].toggle = false
    //     }
    //   }

    //   this.$store.commit('setMetricSelected', {
    //     metric: metric['dataName'],
    //     status: metric['toggle'],
    //     analysis_mesh: metric['analysis_mesh']
    //   })
    // },
    /**
     * @type error
     * only fires on slider error
     */
    sliderError (type, message) {
      console.error('slider errorr type ', type)
      console.error('slider error message', message)
    },
    /**
     * checks if metric is curerently active
     */
    // checkMetricStatus () {
    //   if (this.metricSelected !== null) {
    //     if (this.metricSelected.status === true) {
    //       this.$store.commit('setMetricSelected', this.metricSelected)
    //     }
    //   }
    // },
    /** fires on drag of input sliders
     *  inputs object contain dataName and current slider value
     *  values are stored in this.inputs[i].value
     * @return no return
     *
     */
    // setInputValues: function () {
    //   const values = {}

    //   let inputObjectKeys = Object.keys(this.inputObject)

    //   for (let i = 0; i < inputObjectKeys.length; i++) {
    //     const element = this.inputObject[inputObjectKeys[i]]
    //     values[element['dataName']] = element['value']
    //   }

    //   let selectedModel = filterModelData(
    //     this.modelData,
    //     this.inputHeaders,
    //     values
    //   )

    //   this.$store.commit('setLoadingFlag', true)

    //   this.$store.commit('setModelID', selectedModel[0]['iteration'])

    //   this.$store.dispatch('readModelByID', {
    //     ID: selectedModel[0]['iteration'] + '_option',
    //     type: 'model'
    //   })

    //   // once model has been selected update star rating
    //   this.updateStarRatings(selectedModel[0], this.metricHeaders)
    //   // check to see if metric was loaded
    //   this.checkMetricStatus()
    // },
    /**
     * inputs the selectedmodel chosen by users inputs
     * inputs metricheaders to act as object keys
     * updates star rating value for each of the metric values
     */
    // updateStarRatings: function (selectedModel, metricHeaders) {
    //   for (let key in this.metrics) {
    //     let value = this.metrics[key].scaleQuantile(selectedModel[key])
    //     this.metrics[key].value = selectedModel[key]
    //     this.metrics[key].prettyValue =
    //       Math.round(10 * selectedModel[key]) / 10
    //     // local array to fill
    //     // expected output will be strings
    //     let starArray = ['', '', '', '', '']
    //     // assign star rating to metric object
    //     this.metrics[key].starRating = value
    //     // boleean if value is integer
    //     let valueInteger = Number.isInteger(value)
    //     // floor value
    //     let v = Math.floor(value)
    //     // fills the array based on flooring of value
    //     if (valueInteger) {
    //       starArray.fill('full', 0, v)
    //       starArray.fill('empty', v, 5)
    //     } else if (!valueInteger) {
    //       starArray.fill('full', 0, v)
    //       starArray.splice(v, 0, 'half')
    //       starArray.fill('empty', v + 1, 5)
    //     }
    //     // since array needs to be populated with 5 places
    //     // for array.fill to work, pop off last value
    //     if (starArray.length >= 6) {
    //       starArray.pop()
    //     }
    //     // assign star array to metric object
    //     this.metrics[key].starRatingArray = starArray

    //     this.metrics[key].benchmark = !(
    //       +this.metrics[key].value < this.metrics[key].calcAvgClass.mean
    //     )
    //   }
    // },
    unsubscribeFromStore () {
      // this.unsubscribeFromSettings()
      // this.unsubscribeFromSearchIteration()
    },
    subscribeToStore () {
      // this.unsubscribeFromSettings = this.$store.subscribe((mutation, state) => {
      //   switch (mutation.type) {
      //     case 'setSettings':
      //       this.setInputValues()
      //       break
      //   }
      // })

      // this.unsubscribeFromSearchIteration = this.$store.subscribe((mutation, state) => {
      //   switch (mutation.type) {
      //     case 'setSearchIterationID':

      //       this.awaitSliderRender(this.searchIterationID)

      //       break
      //   }
      // })
    }
  }
}
</script>

<style lang="scss">
// $inputHeight: calc(97vh - #{$navbar-height});

#inputSelector {
  display: flex;
  position: absolute;

  // top: $navbar-height;

  // height: $inputHeight;

  overflow: hidden;

  left: 0px;

  // bottom: calc(#{$input-selector-panel-height} + 30px);

  width: 100%;

  // color: $white;

  z-index: 2;

  pointer-events: none;

  &.mobile {
    top: 35vh !important;
  }

  .slider-parent {
    margin: 0 0 3rem 0;
  }
  .card-body {
    pointer-events: all;
    overflow-y: auto;
    overflow-x: hidden;
    // max-height: $inputHeight;
    width: 500px;

    &.mobile {
      width: 90vw !important;
      height: 63vh;
    }
  }
}

// from https://codepen.io/SanderMoolin/pen/ybaEKE
.slide-up-enter {
  opacity: 0;
  transform: translateY(100vh);
}
.slide-up-enter-to {
  opacity: 1;
}
.slide-up-leave-to {
  opacity: 0;
  transform: translateY(100vh);
}
.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.5s ease-in-out;
}

.metric-slide-up-enter {
  opacity: 0;
}
.metric-slide-up-enter-to {
  opacity: 1;
}

.metric-slide-up-leave-to {
  opacity: 0;
}
.metric-slide-up-enter-active,
.metric-slide-up-leave-active {
  transition: opacity 0.5s ease-in-out;
}
.no-data-legend {
  // background: $no-data-color;
  // border: 1px solid $off-white;
  width: 15px;
  height: 15px;
}

.exit-button {
  &:hover {
    cursor: pointer;
  }
  path {
    // transition: fill $short-transition-ease-in;
  }
}
.exit-button:hover path {
  // fill: $highlight-color;
}

// $border-gradient-radius: 5px;
// $metric-gradient-height: 15px;

.zero-gradient {
  &.zero-text {
  }
  &.zero-color {
    // background: $no-data-color;
    // width: $metric-gradient-height;
    // height: $metric-gradient-height;

    // border-right: 2px solid $black;
    // border-top-left-radius: $border-gradient-radius;
    // border-bottom-left-radius: $border-gradient-radius;
  }
}
.metric-gradient-parent {
  // padding: 0 $metric-gradient-height;
}
.metric-gradient {
  // border-radius: 5px;
  // border-top-right-radius: $border-gradient-radius;
  // border-bottom-right-radius: $border-gradient-radius;

  &.no-zero-grad {
    // border-radius: $border-gradient-radius;
  }

  // height: $metric-gradient-height;
}
.card-body {
  pointer-events: all;
  overflow-y: auto;
  overflow-x: hidden;
  // max-height: $inputHeight;
  width: 500px;

  // &.loading{
  //   pointer-events: none!important;
  // }

  &.mobile {
    width: 90vw !important;
    height: 63vh;
  }
}

.switch-parent {
  height: 50px;
}
.star-parent {
  display: flex;
  vertical-align: middle;
}

.help-icon {
  width: 25px;
  height: 25px;

  padding: 0;
  margin: 0;

  border-radius: 50%;
  // border: 1px solid $white;

  background: none;

  &:hover {
    // background: $highlight-color;
  }
  .active {
    // background-color: $highlight-color !important;
  }
}
.legend-tooltip {
  position: absolute;
  text-align: center;

  left: -130px;
  top: -10px;

  width: 150px;

  .below {
    // background: $off-blue;
  }
  .above {
    // background: $blue;
  }
}
</style>
